import { gql, useMutation } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { useNavigate } from '@faceup/router'
import { ModalConfirm } from '@faceup/ui'
import { Menu, notification, useMessage } from '@faceup/ui-base'
import { ChannelStatus } from '@faceup/utils'
import { useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type {
  DeleteSurveyReportSourceMutation,
  DeleteSurveyReportSourceMutationVariables,
  DuplicateSurvey,
  DuplicateSurveyVariables,
  SurveyOptionsMenu_reportSource,
  ToggleSurveyActivation,
  ToggleSurveyActivationVariables,
} from '../../../__generated__/globalTypes'
import { SurveyExportModal } from './SurveyExportModal'

type Survey = SurveyOptionsMenu_reportSource

const messages = defineMessages({
  viewDetails: 'Administration.surveys.options.viewDetails',
  duplicate: 'Administration.surveys.options.duplicate',
  deactivate: 'Administration.surveys.options.deactivate',
  activate: 'Administration.surveys.options.activate',
  delete: 'Administration.surveys.options.delete',
  confirmDelete: 'Administration.surveys.confirmDelete',
  downloadData: 'Administration.surveys.detail.downloadData',
  changeLanguage: 'Administration.surveys.detail.changeLanguage',
  deleteAnswers: 'Administration.surveys.detail.deleteAnswers',
  copyPrefix: 'Administration.surveys.detail.copyPrefix',
})

export const SurveyOptionsMenuFragments = {
  SurveyOptionsMenu_reportSource: gql`
    fragment SurveyOptionsMenu_reportSource on ReportSource {
      id
      createdAt
      name
      createdByMember {
        id
        name
      }
      cases {
        ... on Submission {
          id
        }
      }
      isEnabled
    }
  `,
}

const mutations = {
  DeleteReportSource: gql`
    mutation DeleteSurveyReportSourceMutation($input: DeleteReportSourceInput!) {
      deleteReportSource(input: $input) {
        reportSource {
          id
          isDeleted
        }
      }
    }
  `,
  DuplicateSurvey: gql`
    mutation DuplicateSurvey($input: DuplicateSurveyInput!) {
      duplicateSurvey(input: $input) {
        survey {
          id
        }
      }
    }
  `,
  ToggleSurveyActivation: gql`
    mutation ToggleSurveyActivation($input: ToggleSurveyActivationInput!) {
      toggleSurveyActivation(input: $input) {
        survey {
          id
          isEnabled
        }
      }
    }
  `,
}

type MenuOptions =
  | 'viewDetails'
  | 'duplicate'
  | 'deactivate'
  | 'delete'
  | 'downloadData'
  | 'changeLanguage'
  | 'deleteAnswers'

export const SurveyOptionsMenu = ({
  survey,
  optionsToDisplay,
}: {
  survey: Survey
  optionsToDisplay: MenuOptions[]
}) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const message = useMessage()
  const navigate = useNavigate()

  const [deleteSource, { loading }] = useMutation<
    DeleteSurveyReportSourceMutation,
    DeleteSurveyReportSourceMutationVariables
  >(mutations.DeleteReportSource, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    refetchQueries: ['SurveysTableQuery'],
    onCompleted: () => {
      message.success(formatMessage(sharedMessages.savedMessage))
      navigate(routes => routes.surveys())
    },
  })

  const [duplicateSurvey, { loading: duplicateLoading }] = useMutation<
    DuplicateSurvey,
    DuplicateSurveyVariables
  >(mutations.DuplicateSurvey, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    refetchQueries: ['SurveysTableQuery'],
    onCompleted: () => {
      message.success(formatMessage(sharedMessages.savedMessage))
      navigate(routes => routes.surveys())
    },
  })

  const [toggleSurveyActivation] = useMutation<
    ToggleSurveyActivation,
    ToggleSurveyActivationVariables
  >(mutations.ToggleSurveyActivation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => message.success(formatMessage(sharedMessages.savedMessage)),
    refetchQueries: ['SurveysTableQuery'],
  })

  const submissionsCount = survey.cases?.length ?? 0

  const items = optionsToDisplay.map(key => {
    switch (key) {
      case 'viewDetails':
        return {
          key,
          label: <FormattedMessage {...messages.viewDetails} />,
        }
      case 'duplicate':
        return {
          key,
          label: <FormattedMessage {...messages.duplicate} />,
          disabled: duplicateLoading,
        }
      case 'deactivate':
        return {
          key,
          label: <FormattedMessage {...messages.deactivate} />,
          disabled: !survey.isEnabled,
        }
      case 'delete':
        return {
          key,
          label: <FormattedMessage {...messages.delete} />,
          danger: true,
        }
      case 'downloadData':
        return {
          key,
          label: <FormattedMessage {...messages.downloadData} />,
          disabled: submissionsCount === 0,
        }
      case 'changeLanguage':
        return {
          key,
          label: <FormattedMessage {...messages.changeLanguage} />,
          disabled: true,
          hidden: true,
        }
      case 'deleteAnswers':
        return {
          key,
          label: <FormattedMessage {...messages.deleteAnswers} />,
          disabled: true,
          danger: true,
        }
    }
  })

  return (
    <>
      <Menu
        onClick={menuItem => {
          switch (menuItem.key as MenuOptions) {
            case 'delete':
              setConfirmDeleteModalOpen(true)
              break
            case 'duplicate':
              duplicateSurvey({
                variables: {
                  input: {
                    motherId: getMotherId(),
                    channelId: survey?.id ?? '',
                    newName: formatMessage(messages.copyPrefix, { name: survey?.name }),
                  },
                },
              })
              break
            case 'deactivate':
              toggleSurveyActivation({
                variables: {
                  input: {
                    motherId: getMotherId(),
                    reportSourceId: survey?.id ?? '',
                    status: ChannelStatus.Disabled,
                  },
                },
              })
              break
            case 'viewDetails':
              navigate(routes => routes.surveyDetail({ id: survey?.id ?? '' }))
              break
            case 'downloadData':
              setIsExportModalOpened(true)
              break
            case 'changeLanguage': // fall-through
            case 'deleteAnswers': // fall-through
              break
          }
        }}
        items={items}
      />
      <ModalConfirm
        title={<FormattedMessage {...sharedMessages.delete} />}
        variant='danger'
        opened={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          deleteSource({
            variables: {
              input: { motherId: getMotherId(), reportSourceId: survey?.id ?? '' },
            },
          })
          setConfirmDeleteModalOpen(false)
        }}
        confirmState={{ loading }}
      >
        <FormattedMessage {...messages.confirmDelete} />
      </ModalConfirm>
      {survey?.id && (
        <SurveyExportModal
          opened={isExportModalOpened}
          reportSourceId={survey.id}
          onClose={() => setIsExportModalOpened(false)}
        />
      )}
    </>
  )
}
