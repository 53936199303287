import { prehashPassword } from '../password'
import { createSystemRecoveryKey, showRecoveryKey } from '../recovery'

type MigrationFromE2EEPayload = {
  systemPublicKey: string
  password: string
  salt: string
  recoveryKeyEncrypted: string
  recoveryKeyEncryptedNonce: string
}

export const processMigrationFromE2EE = async ({
  password,
  salt,
  recoveryKeyEncrypted,
  recoveryKeyEncryptedNonce,
  systemPublicKey,
}: MigrationFromE2EEPayload) => {
  const { passwordKey, passwordKeyPrehash } = await prehashPassword({ password, salt })

  const recoveryKeyPayload = await showRecoveryKey(
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    passwordKey
  )

  if (typeof recoveryKeyPayload === 'string') {
    throw new Error(recoveryKeyPayload)
  }

  const systemRecoveryKeyPayload = await createSystemRecoveryKey(
    recoveryKeyPayload.recoveryKey,
    systemPublicKey
  )

  if (typeof systemRecoveryKeyPayload === 'string') {
    throw new Error(systemRecoveryKeyPayload)
  }

  return {
    currentPasswordPrehash: passwordKeyPrehash,
    newPasswordPrehash: passwordKeyPrehash,
    systemRecoveryKey: systemRecoveryKeyPayload.systemRecoveryKey,
    recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce,
    newSalt: salt,
  }
}
