import { readClosingComment } from '@faceup/crypto'
import { useCryptoErrorHandler } from '@faceup/report'
import { useCallback } from 'react'

export const useClosingComment = () => {
  const handleError = useCryptoErrorHandler()

  const decrypt = useCallback(
    async (body: string | null, nonce: string | null, encryptionKey: string | null) => {
      const payload = await readClosingComment(body ?? '', nonce ?? '', encryptionKey ?? '')

      if (typeof payload === 'string') {
        handleError(payload)
        throw new Error(payload)
      }

      const { text } = payload
      return text
    },
    [handleError]
  )
  return { decrypt }
}
