import { TokenType } from '@faceup/utils'
import { getCurrentEncryptionVersion } from '../end2end/login'
import { savePersonalKeys } from '../keys'
import { changePassword, prehashPassword } from '../password'
import { createRecoveryKey, recoverPrivateKey } from '../recovery'
import { processVersionMigration } from './processVersionMigration'

export type ProcessForgottenPasswordPayload = {
  password: string
  publicKey: string
  privateKey: string
  privateKeyRecovery: string
  privateKeyRecoveryNonce: string
  recoveryKey: string | undefined
  systemPublicKey: string
  isE2EE: boolean
  version: number
}

export const processForgottenPassword = async ({
  password,
  privateKeyRecovery,
  privateKeyRecoveryNonce,
  publicKey,
  privateKey,
  recoveryKey,
  systemPublicKey,
  isE2EE,
  version,
}: ProcessForgottenPasswordPayload) => {
  if (version !== getCurrentEncryptionVersion()) {
    const payload = await processVersionMigration({
      password,
      version,
      publicKey,
      privateKey,
      systemPublicKey,
    })

    if (typeof payload === 'string') {
      return payload
    }

    const { passwordKey } = await prehashPassword({
      password,
      salt: payload.newSalt,
      version: getCurrentEncryptionVersion(),
    })

    await savePersonalKeys({
      publicKey,
      privateKey: payload.newPrivateKeyEncrypted,
      nonce: payload.newNonce,
      passwordKey,
      rememberMe: false,
      version: getCurrentEncryptionVersion(),
    })

    const mutationInput = {
      tokenType: TokenType.ForgottenPassword as const,
      name: null,
      passwordPrehash: payload.newPasswordPrehash,
      nonce: payload.newNonce,
      salt: payload.newSalt,
      privateKeyEncrypted: payload.newPrivateKeyEncrypted,
      publicKey,
      recoveryKeyEncrypted: payload.recoveryKeyEncrypted,
      recoveryKeyEncryptedNonce: payload.recoveryKeyEncryptedNonce,
      privateKeyRecovery: payload.privateKeyRecovery,
      privateKeyRecoveryNonce: payload.privateKeyRecoveryNonce,
      ...(!isE2EE && { recoveryKeySystemEncrypted: payload.systemRecoveryKey }),
    }

    return [mutationInput, payload.recoveryKeyPlain] as const
  }

  const recoveryPayload = await recoverPrivateKey(
    privateKeyRecovery,
    recoveryKey ?? '',
    privateKeyRecoveryNonce
  )

  if (typeof recoveryPayload === 'string') {
    return recoveryPayload
  }

  const passwordPayload = await changePassword(password ?? '', {
    publicKey,
    privateKey: recoveryPayload.privateKey,
  })

  if (typeof passwordPayload === 'string') {
    return passwordPayload
  }

  const newRecoveryPayload = await createRecoveryKey(
    passwordPayload.privateKeyEncrypted,
    passwordPayload.nonce,
    passwordPayload.passwordKey,
    recoveryKey ?? undefined
  )

  if (typeof newRecoveryPayload === 'string') {
    return newRecoveryPayload
  }

  const savePayload = await savePersonalKeys({
    publicKey,
    privateKey: passwordPayload.privateKeyEncrypted,
    nonce: passwordPayload.nonce,
    passwordKey: passwordPayload.passwordKey,
    rememberMe: false,
    version: getCurrentEncryptionVersion(),
  })

  if (typeof savePayload === 'string') {
    return savePayload
  }

  const mutationInput = {
    tokenType: TokenType.ForgottenPassword as const,
    name: null,
    passwordPrehash: passwordPayload.password,
    nonce: passwordPayload.nonce,
    salt: passwordPayload.salt,
    privateKeyEncrypted: passwordPayload.privateKeyEncrypted,
    publicKey,
    recoveryKeyEncrypted: newRecoveryPayload.recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce: newRecoveryPayload.recoveryKeyEncryptedNonce,
    privateKeyRecovery: newRecoveryPayload.privateKeyRecovery,
    privateKeyRecoveryNonce: newRecoveryPayload.privateKeyRecoveryNonce,
  }

  return [mutationInput, null] as const
}
